<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg "  >
        <div class="main-card uk-animation-slide-bottom-small"  >
          <page-title></page-title>
          <div v-if="responseMassage !== ''" style= "padding:5vh;box-sizing: border-box;font-size:1.5rem;">
            {{responseMassage}}
          </div>
          <form id="inquriy" class="uk-form-horizontal form-cls" v-if="responseMassage === ''">
            <div class="uk-margin input-cls" v-for="(item, i) in items" :key="i">
                <label class="uk-form-label" for="form-horizontal-text">{{item.required + item.title}}</label>
                <div class="uk-form-controls ">
                    <input v-if="item.type === 'text'" class="uk-input" :type="item.type" :id="item.name" :name="item.name" @input="setText(item.required, $event)" >
                    <textarea v-if="item.type === 'textarea'" class="uk-textarea" rows="5" :id="item.name" :name="item.name" @input="setText(item.required, $event)"></textarea>
                </div>
            </div>
            <div class="uk-margin" style="text-align:center;">
              <a :class="{'is-passive': isPassive}" class="btn" v-on:click="inqriyPost"><span>＞＞＞送信する</span></a>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
export default {
  name: 'Contact',
  data: () => ({
    items:[],
    chk:[],
    isPassive: true ,
    responseMassage : ''
  }),
  methods : {
    setText(required,e) {
      if(required === '*' ){
        if(e.target.value.length > 0) {
          this.chk.splice(this.chk.indexOf(e.target.name), 1)
        }else{
          this.chk.push(e.target.name);
        }
      }
    },
    inqriyPost: function(){
      let formData = new FormData(document.getElementById('inquriy'));

      const formDataJson = {};
      for (const [key, value] of formData.entries()) {
        formDataJson[key] = value;
      }
      this.$_apiPost( JSON.stringify(formDataJson),'/inquiry/mix',(response) => { 
        for(let item of this.items){
              document.getElementById(item.name).value = "";
            }
            this.responseMassage = response.data;
       }); 
    }
  },
  mounted: function() {
    let requiredItems = this.items.filter(item => item.required === '*');
    for(const item of requiredItems) this.chk.push(item.name);
  },
  created() {
    // メインメニュー作成
    this.items = this.commonSetting.contactItem;
    this.responseMassage = '';
  },
  watch: {
    chk : function () {
      this.isPassive = (this.chk.length != 0);
    }
  },

}
</script>




<style scoped lang="scss">

.parallax-bg {
  background-image:url(~@/assets/img/bg-image1.jpg);
  
}
.form-cls{
  margin: 5vh 10vw;
}
.input-cls{
  margin: 2vh 5vw;

  .uk-form-label{
    color: #fff;
  }
}

.btn{
  padding: 1vh 1vw;
  font-size: 1rem;
}

.is-passive{
  pointer-events:none !important;
  border: 1px solid rgb(109, 109, 109);/* ボーダーの色と太さ */
    span{
      color:rgb(109, 109, 109)!important;
    }
}
  
</style>
